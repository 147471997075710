import {
    SET_APP_EDITABLE
  } from '../../types/appConstants'
  
  // **  Initial State
  const initialState = {
    isAppEditable: true
  }
  
  const appeditable = (state = initialState, action) => {  
    switch (action.type) {
      case SET_APP_EDITABLE:
        return { ...state, isAppEditable: action.payload }
      default:
        return state
    }
  }
  
  export default appeditable
  