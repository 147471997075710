// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import tbadata from './tbadata'
import framework from './framework'
import assessment from './assessment'
import appeditable from './appeditable'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  tbadata,
  framework,
  assessment,
  appeditable
})

export default rootReducer
