const localConfig = {
    REACT_APP_AZURE_CLIENT_ID: "b9f193eb-0b17-49bf-ad62-97d9da8277e6",
    //REACT_APP_API_URL: "http://localhost:7071",
    REACT_APP_API_URL: 'https://func-mg-dev1-t1-esgtbaapi-01.api-apps-inv-dev.mandg.co.uk',
    REACT_APP_AZURE_REDIRECT_URL: "http://localhost:3000",
    REACT_APP_AZURE_AUTHORITY: "https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66",
    REACT_APP_AZURE_SCOPE: "https://savingsandinvestments.onmicrosoft.com/sp-mg-tst1-t1-esgtba-01/user_impersonation",
    REACT_APP_AUDIENCE: "https://savingsandinvestments.onmicrosoft.com/sp-mg-tst1-t1-esgtba-01",
    REACT_APP_TENANT_ID: "aa42167d-6f8d-45ce-b655-d245ef97da66"
}

export const getConfig = () => {
    if (process.env.NODE_ENV === 'development') {
        return localConfig
    } else {
        return (window).config
    }
}