import {
  UPDATE_FRAMEWORK,
  UPDATE_UOP_FRAMEWORK,
  UPDATE_SLB_FRAMEWORK,
  SET_FRAMEWORK,
  RESET_FRAMEWORK,
  UPDATE_FRAMEWORK_JSON
} from '../../types/appConstants'

// **  Initial State
const initialState = { }

const frameworkReducer = (state = initialState, action) => {  
  switch (action.type) {
    case UPDATE_FRAMEWORK:      
      return { ...state, ...action.payload }      
    case UPDATE_UOP_FRAMEWORK:
      return { ...state, ...action.payload }
    case UPDATE_FRAMEWORK_JSON:
      return { ...state, 
         jsonFields: {
         ...state.jsonFields,
         ...action.payload
       } 
      }  
    case UPDATE_SLB_FRAMEWORK:
      return { ...state, ...action.payload }
    case SET_FRAMEWORK:
      return { ...state, ...action.payload }
    case RESET_FRAMEWORK:      
      return { }
    default:
      return state
  }
}

export default frameworkReducer
